.video-js {
  .vjs-time-control, .vjs-picture-in-picture-control {
    display: none;
  }

  .vjs-big-play-button .vjs-icon-placeholder:before {
    font-size: 32px;
    line-height: 46px;
  }

  button:focus-visible {
    outline: none !important;
  }

  .vjs-button > .vjs-icon-placeholder:before{
    font-size: 20px;
  }
}

.affiliates-size {
  height: 100% !important;
  width: 100% !important;
}

.brands-size {
  height: 500px !important;
  width: 328px !important;
}

@media (max-width: 450px) {
  .video-js {
    height: 100% !important;
    width: 100% !important;
  }
}
